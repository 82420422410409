import * as React from "react";
import { ReactComponent as ConvoIcon } from "../../../imgs/icons/mindful-conversations.svg";
import { ReactComponent as Arrow } from "../../../imgs/icons/arrow.svg";
import Button from "../../Button";
import useWindowSize from "../../../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { Video } from "../../../JSON/schemas";
import VideoData from "../../../JSON/VideoData";
import VideoTile from "../../VideoTile";
import { useHistory } from "react-router";

function MindfulConvTile() {
	const size = useWindowSize();
	// Mobile up to 1200 as it works nicer
	const isMobile = size.width !== undefined && size.width < 1200;
	const [videos, setVideos] = useState<Video[]>([]);
	const history = useHistory();
	useEffect(() => {
		const videosTemp: Video[] = [];
		for (let i = 0; i < 4; i++) {
			videosTemp.push(VideoData[i]);
		}
		setVideos(videosTemp);
	}, []);

	const text: JSX.Element = (
		<span>
			See all Videos & Podcasts &nbsp;
			<Arrow stroke="white"></Arrow>
		</span>
	);
	return (
		<div className="conversation-tile">
			<div className="content mx-auto">
				<div className="row">
					<div className="col-xl-6 col-md-12 col-sm-12 justify-content-center d-flex">
						<div className="box">
							<ConvoIcon className="convo-icon" />
							<h2>Mindful Conversations</h2>
							<p className="mx-auto">
								Hear from experts and leaders in the field and
								be inspired by real-life stories from
								individuals that have overcome challenges. We
								promise not to leave any topic unturned so tune
								in every month for a new series.
							</p>
							{!isMobile && (
								<Button
									ariaLabel="all-videos-podcasts"
									text={text}
									color="blue"
									classOverride="mindful-button"
									onClick={() =>
										history.push("/mindful-conversations")
									}
								/>
							)}
						</div>
					</div>
					{videos.length === 4 && (
						<div className="col-xl-6 col-lg-7 col-sm-10 preview-tiles mx-auto">
							<div className="row">
								<VideoTile
									title={videos[0].title}
									previewDisplay={videos[0].link}
									id={videos[0].link}
								/>
								<VideoTile
									title={videos[1].title}
									previewDisplay={videos[1].link}
									id={videos[1].link}
								/>
							</div>
							<div className="row">
								<VideoTile
									title={videos[2].title}
									previewDisplay={videos[2].link}
									id={videos[2].link}
								/>

								<VideoTile
									title={videos[3].title}
									previewDisplay={videos[3].link}
									id={videos[3].link}
								/>
							</div>
						</div>
					)}
					<div className="col-sm-12 see-all-btn">
						{isMobile && (
							<Button
								ariaLabel="all-videos-podcasts"
								text={text}
								color="blue"
								classOverride="mindful-button"
								onClick={() =>
									history.push("/mindful-conversations")
								}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default MindfulConvTile;
