import React, { useState } from "react";
import NavBar from "../NavBar";
import Button from "../Button";
import FeelingDisplay from "./FeelingDisplay";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../imgs/icons/arrow.svg";
import MMDrop from "../MMDrop";
import useWindowSize from "../../hooks/useWindowSize";

interface Props {
	match: {
		params: {
			mood: string;
		};
	};
}

function RateFeeling(props: Props) {
	const size = useWindowSize();
	const isMobile = size.width !== undefined && size.width < 992;

	const text: JSX.Element = (
		<span>
			Next &nbsp;<Arrow stroke="#262626"></Arrow>
		</span>
	);
	const mood = props.match.params.mood;
	const [rating, setRating] = useState<string | undefined>(undefined);

	function idk(i: number) {
		if (rating === i.toString()) {
			return "active";
		}
		return "";
	}

	function createDesktopRating() {
		const buttonGroup = [];
		for (let i = 1; i < 11; i++) {
			buttonGroup.push(
				<button
					className={"btn btn-rate  " + idk(i)}
					onClick={() => setRating(i.toString())}>
					{i}
				</button>
			);
		}
		return buttonGroup;
	}

	return (
		<>
			<NavBar></NavBar>
			<div className="mind-mapper rate-feeling">
				<div className="content mx-auto">
					<div className="box">
						<h2 className="mx-auto">
							Lastly, how strongly do you feel this emotion?
						</h2>
						<p className="p-desc">
							Recommended skills are based on the intensity of the
							emotion you are feeling. Remember different tools
							need to be used at different times on your journey.
							Knowing when to use what skill is an important part
							of learning about how to effectively regulate your
							emotions.
						</p>

						<div className="mood-col justify-content-center d-flex mx-auto">
							<FeelingDisplay mood={mood} selected={true} />
						</div>

						{!isMobile && (
							<div className="row mx-auto my-auto justify-content-center d-flex">
								<div className="rate-descr">Low</div>
								<div className="line"></div>
								{createDesktopRating()}
								<div className="rate-descr">High</div>
							</div>
						)}

						{isMobile && (
							<div className="justify-content-center d-flex">
								<MMDrop
									placeHolder="Please select"
									rating={rating}
									setRating={setRating}
									values={[
										"1",
										"2",
										"3",
										"4",
										"5",
										"6",
										"7",
										"8",
										"9",
										"10",
									]}
								/>
							</div>
						)}

						<div className="next-button justify-content-center d-flex">
							{rating !== undefined && (
								<Link
									to={"/mind-mapper/" + mood + "/" + rating}>
									<Button
										ariaLabel="next"
										text={text}
										color="yellow"
										onClick={() =>
											window.scrollTo({
												behavior: "smooth",
												top: 0,
											})
										}
									/>
								</Link>
							)}
						</div>
						<div className="justify-content-center d-flex">
							<Link
								to="/mind-mapper/select"
								aria-label="home"
								className="redirect">
								Back
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default RateFeeling;
