import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../imgs/mindscape-logo.svg";
import { ReactComponent as Instagram } from "../../imgs/icons/instagram.svg";
import { ReactComponent as LinkedIn } from "../../imgs/icons/linkedin.svg";
import { ReactComponent as Youtube } from "../../imgs/icons/youtube.svg";
import { ReactComponent as Podcast } from "../../imgs/icons/podcast.svg";
import { ReactComponent as Spotify } from "../../imgs/icons/spotify.svg";

function Footer() {
	return (
		<div className="footer">
			<div className="wave"></div>
			<div className="yellow-box">
				<div className="content mx-auto">
					<div className="logo">
						<Logo />
					</div>
					<p className="mind-disclaimer">
						Mindscape is in no way part of the Mind network or
						affiliated to the mental health charity Mind
					</p>

					<div className="row">
						<div className="col-lg-3 col-sm-12">
							<h3>Useful links</h3>
							<div className="list-group">
								<a
									href="https://www.samaritans.org"
									target="_blank"
									rel="noreferrer">
									Samaritans
								</a>
								<a
									href="https://www.nhs.uk"
									target="_blank"
									rel="noreferrer">
									NHS
								</a>
								<a
									href="https://suicidepreventionlifeline.org"
									target="_blank"
									rel="noreferrer">
									National Suicide Prevention Lifeline (USA
									only)
								</a>
							</div>
						</div>
						<div className="col-lg-3 col-sm-12">
							<h3>Resources</h3>
							<div className="list-group">
								<Link
									to="/journal"
									aria-label="Journal"
									className="footer-text">
									Journal
								</Link>
								<Link
									to="/mindful-conversations"
									aria-label="Mindful Conversations"
									className="footer-text">
									Mindful Conversations
								</Link>
								<Link
									to="/skills-toolkit"
									aria-label="Skills Toolkit"
									className="footer-text">
									Skills Toolkit
								</Link>
								<Link
									to="/mind-mapper"
									aria-label="Mind Mapper"
									className="footer-text">
									Mind Mapper
								</Link>
							</div>
						</div>
						<div className="col-lg-3 col-sm-12">
							<h3>Other Links</h3>
							<div className="list-group">
								<Link
									to="/get-involved"
									aria-label="Get Involved"
									className="footer-text">
									Get Involved
								</Link>
								<p
									className="footer-text"
									onClick={() =>
										window.open(
											// eslint-disable-next-line @typescript-eslint/no-var-requires
											require(`../../Documents/Legal/Privacy Policy.pdf`)
												.default
										)
									}>
									Privacy Policy
								</p>
								<p
									className="footer-text"
									onClick={() =>
										window.open(
											// eslint-disable-next-line @typescript-eslint/no-var-requires
											require(`../../Documents/Legal/Cookie Policy.pdf`)
												.default
										)
									}>
									Cookie Policy
								</p>
							</div>
						</div>
						<div className="col-lg-3 col-sm-12">
							<h3>Keep in touch</h3>
							<div className="media-group">
								<Instagram
									className="media-icons"
									onClick={() =>
										window.open(
											"https://www.instagram.com/mindscapeyourjourney/"
										)
									}
								/>
								<LinkedIn
									className="media-icons"
									onClick={() =>
										window.open(
											"https://www.linkedin.com/company/mindscapeyourjourney/"
										)
									}
								/>
								<Youtube
									className="media-icons"
									onClick={() =>
										window.open(
											"https://www.youtube.com/channel/UCVhL4WFwgxLC_xyAAkntltQ/featured"
										)
									}
								/>
								<Podcast
									className="media-icons"
									onClick={() =>
										window.open(
											"https://podcasts.apple.com/gb/podcast/mindful-conversations/id1582858291"
										)
									}
								/>
								<Spotify
									className="media-icons"
									onClick={() =>
										window.open(
											"https://open.spotify.com/show/2RhgAZ6VbHhOMDpyFOpZXg?si=3XxRJ-YnRIusDfU1TPgyVQ&dl_branch=1&nd=1"
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;
