import * as React from "react";
import Button from "../../Button";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../imgs/icons/arrow.svg";

function LandingTile() {
	const text: JSX.Element = (
		<span>
			Get started &nbsp;<Arrow stroke="#262626"></Arrow>
		</span>
	);
	return (
		<div className="landing">
			<div className="content mx-auto">
				<div className="row">
					<div className="col-lg-6 col-sm-12 left-content d-flex">
						<div>
							<h1>Guiding you to better mental health</h1>
							<p>
								Navigating your mind can feel like a complicated
								journey but it doesn't have to be. We're
								dedicated to helping each other become the best
								version of ourselves by sharing insight and
								tools to help you stay on track.
							</p>
						</div>
					</div>
					<div className="col-lg-6 col-sm-12 justify-content-center d-flex">
						<div className="box justify-content-center d-flex">
							<div>
								<h3>
									Take your first step towards better mental
									health
								</h3>
								<p>
									Use our interactive <b>Mind Mapper</b> tool
									to help identify what skills could help you
									manage your emotional responses, build
									better relationships and empower you to
									thrive
								</p>
								<Link
									to="/mind-mapper"
									aria-label="mind-mapper">
									<Button
										ariaLabel="get-started"
										text={text}
										color="white"
										onClick={() =>
											window.scrollTo({
												behavior: "smooth",
												top: 0,
											})
										}
									/>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LandingTile;
