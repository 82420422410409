import React from "react";

interface Props {
	mood: string;
	selected: boolean;
}

function FeelingDisplay(props: Props) {
	return (
		<div className={`feeling-display ${props.selected ? "selected" : ""}`}>
			<div
				className={`mood ${props.mood}-bg my-auto ${
					props.selected ? "selected" : ""
				}`}>
				<span
					className={`mood ${props.mood}-face ${
						props.selected ? "selected" : ""
					}`}
				/>
			</div>
			<p className={`mood-name ${props.selected ? "selected" : ""}`}>
				{props.mood}
			</p>
		</div>
	);
}

export default FeelingDisplay;
