import React, { FunctionComponentElement, useEffect, useState } from "react";
import { useParams } from "react-router";
import JournalData from "../../JSON/JournalData";
import { Journal } from "../../JSON/schemas";
import Footer from "../Footer";
import JournalTile from "../HomePage/Journal";
import NavBar from "../NavBar";
import Components from "../../Documents/Journals/Components";

export default () => {
	const { journalId } = useParams<{ journalId: string }>();

	const [journal, setJournal] = useState<Journal | null>(null);
	const [JournalContent, setJournalContent] = useState<
		// eslint-disable-next-line @typescript-eslint/ban-types
		FunctionComponentElement<{}>
	>();

	useEffect(() => {
		JournalData.forEach(journalRecord => {
			if (journalRecord.id === journalId) {
				setJournal(journalRecord);
				// setJournalContent(Components(journalRecord.id)); // TODO: Make it this later on...
				setJournalContent(Components("1"));
			}
		});
	}, [journalId]);

	return (
		<div className="container-flex" style={{ width: "100vw" }}>
			<NavBar />
			<div className="journal-entry-page">
				<div className="content mx-auto">
					<div className="justify-content-center d-flex my-auto">
						{journal !== null ? (
							<div className="box">
								<span className="journeys">Journeys</span>
								<h1>{journal.title}</h1>
								{journal.author && (
									<h2>Author: {journal.author}</h2>
								)}
								{/* <h2>{journal.description}</h2> */}
								{/* <div className="journal-image">
									<img
										src={
											// eslint-disable-next-line @typescript-eslint/no-var-requires
											require(`../../imgs/placeholder/${journal.image}`)
												.default
										}
										alt={journal.image}
									/>
								</div> */}
								{JournalContent !== undefined && JournalContent}
								{/* {journal.data.map((property, index) => {
									if (property.header !== undefined)
										return (
											<h3 key={`h3-${index}`}>
												{property.header}
											</h3>
										);
									if (property.paragraph !== undefined)
										return (
											<p key={`p-${index}`}>
												{property.paragraph}
											</p>
										);
									if (property.image !== undefined)
										return (
											<div
												key={`div-img-${index}`}
												className="journal-image">
												<img
													src={
														// eslint-disable-next-line @typescript-eslint/no-var-requires
														require(`../../imgs/placeholder/${property.image}`)
															.default
													}
													alt={property.image}
												/>
											</div>
										);
								})} */}
							</div>
						) : (
							`404 Error: Journal not found`
						)}
					</div>
				</div>
			</div>

			<JournalTile />
			<Footer />
		</div>
	);
};
