import React from "react";
import Footer from "../components/Footer";
import AboutUsTile from "../components/HomePage/AboutUsTile";
import JournalTile from "../components/HomePage/Journal";
import LandingTile from "../components/HomePage/LandingTile";
import MentalExerciseTile from "../components/HomePage/MentalExerciseTile";
import MindfulConvTile from "../components/HomePage/MindfulConvTile";
import NavBar from "../components/NavBar";
import PartnersTile from "../components/HomePage/Partners";
import SkillsToolkitTile from "../components/HomePage/SkillsToolkitTile";

function Main() {
	return (
		<>
			<div className="container-flex" style={{ width: "100vw" }}>
				<NavBar />
				<LandingTile />
				<MentalExerciseTile />
				<MindfulConvTile />
				<SkillsToolkitTile />
				<AboutUsTile />
				<PartnersTile />
				<JournalTile />
				<Footer />
			</div>
		</>
	);
}

export default Main;
