import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useWindowSize from "../../../hooks/useWindowSize";

import BBS from "../../../imgs/images/partners/BBC.png";
import MHF from "../../../imgs/images/partners/MHF.png";
import UBS from "../../../imgs/images/partners/UBS.png";

function PartnersTile() {
	const size = useWindowSize();
	const isMobile = size.width !== undefined && size.width < 992;

	return (
		<div className="partner-tile">
			<div className="content mx-auto">
				<h2>Who we've worked with </h2>

				{!isMobile && (
					<div className="row">
						<div className="col-lg-4 col-md-12 col-sm-12 my-auto justify-content-end d-flex">
							<img src={MHF} alt="" />
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12 my-auto justify-content-center d-flex">
							<img src={BBS} alt="" />
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12 my-auto justify-content-start d-flex">
							<img src={UBS} alt="" />
						</div>
					</div>
				)}
				{isMobile && (
					<Carousel
						touch={true}
						controls={false}
						indicators={true}
						pause={"hover"}
						wrap={true}
						className="carousel">
						<Carousel.Item>
							<div className=" my-auto justify-content-center d-flex">
								<img src={MHF} alt="" />
							</div>
						</Carousel.Item>
						<Carousel.Item>
							<div className=" my-auto justify-content-center d-flex">
								<img src={BBS} alt="" />
							</div>
						</Carousel.Item>
						<Carousel.Item>
							<div className="my-auto justify-content-center d-flex">
								<img src={UBS} alt="" />
							</div>
						</Carousel.Item>
					</Carousel>
				)}
			</div>
		</div>
	);
}

export default PartnersTile;
