import _ from "lodash";
import React, { useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import useWindowSize from "../hooks/useWindowSize";

interface Props {
	placeHolder: string;
	rating: string | undefined;
	setRating: React.Dispatch<React.SetStateAction<string | undefined>>;
	values: string[];
}

function MMDrop({ placeHolder, rating, setRating, values }: Props) {
	const size = useWindowSize();
	const isMobile = size.width !== undefined && size.width < 992;

	const myRef = useRef<HTMLDivElement>(null);

	const executeScroll = () => {
		if (myRef.current !== null && isMobile)
			window.scrollTo({
				top: myRef.current.offsetTop - 150,
				left: 0,
				behavior: "smooth",
			});
	};
	return (
		<>
			<Dropdown ref={myRef} onToggle={executeScroll}>
				<Dropdown.Toggle variant="yellow">
					{rating === undefined || rating === "emptyValue"
						? placeHolder
						: _.startCase(rating)}
				</Dropdown.Toggle>

				<Dropdown.Menu className="ms-dropdown-menu">
					{values.map((value, index) => {
						return (
							<Dropdown.Item
								eventKey={`${index + 1}`}
								onClick={() => setRating(value)}>
								{value === "1"
									? "1 - Low"
									: value === "10"
									? "10 - High"
									: value === "emptyValue"
									? "Clear filter"
									: _.startCase(value)}
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
}

export default MMDrop;
