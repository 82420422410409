import React, { useState } from "react";
import NavBar from "../NavBar";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { ReactComponent as HappyFace } from "../../imgs/icons/happy-face.svg";
import Button from "../Button";
import FormGroup from "react-bootstrap/esm/FormGroup";
import Footer from "../Footer";

function GetInvolved() {
	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({ email: "" });

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (event: any) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		}
		setValidated(true);
	};

	function checkSubjects(subject: string): void {
		const element = document.getElementById("other");
		if (subject === "Other" && element != null) {
			element.style.display = "block";
		} else if (element != null) {
			element.style.display = "none";
		}
	}

	return (
		<>
			<NavBar />
			<div className="get-involved">
				<div className="content mx-auto">
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 left-content d-flex top-text">
							<div>
								<HappyFace className="happyface" />
								<h1>Get involved</h1>
								<p className="disclaimer">
									Mindscape cannot provide medical advice,
									please use our free resources or click on
									the emergency help button at the top of the
									page to access support.
								</p>
								<p>
									Mindscape’s mission is to build and empower
									a whole community through sharing real-life
									experiences, knowledge, and skills so that
									we can support each other on our wellbeing
									journey. If you are interested in getting
									involved whether through sharing your
									personal experience or as an expert or
									professional in the field, we would love to
									hear from you. Please use the form to submit
									an interest and a member of our team will be
									in touch.
								</p>
								<p>
									For all other Mindscape queries please
									submit a general query.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 left-content d-flex">
							<div className="box justify-content-center d-flex">
								<Form
									action="https://formspree.io/f/xbjqbzrb"
									method="post"
									noValidate
									validated={validated}
									onSubmit={handleSubmit}
									target="_blank">
									<Form.Group controlId="validationCustom01">
										<FormGroup controlId="formGroupName">
											<div className="row">
												<div className="col">
													<Form.Label>
														First Name
													</Form.Label>
													<Form.Control
														name="firstname"
														required></Form.Control>
												</div>
												<div className="col">
													<Form.Label>
														Last Name
													</Form.Label>
													<Form.Control
														name="lastname"
														required></Form.Control>
												</div>
											</div>
										</FormGroup>

										<FormGroup controlId="formGroupEmail">
											<Form.Label>
												Email Address
											</Form.Label>
											<Form.Control
												type="email"
												name="email"
												required
												onChange={handleChange}
											/>
											<FormControl.Feedback type="invalid">
												Please enter a valid email
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup controlId="formGroupSubject">
											<Form.Label>Subject</Form.Label>
											<Form.Control
												as="select"
												name="_subject"
												custom
												onChange={e => {
													checkSubjects(
														e.target.value
													);
												}}
												required>
												<option>General</option>
												<option>
													Journal Submission
												</option>
												<option>
													Mindful Conversations –
													register interest
												</option>
												<option>
													Mindscape Ambassador
													Programme
												</option>
												<option>Media Enquiries</option>
												<option>
													Partnership Enquiries
												</option>
												<option>Other</option>
											</Form.Control>
											<Form.Control
												id="other"
												name="_subject"
												placeholder="Please specify"
												className="other"></Form.Control>
										</FormGroup>

										<Form.Label>Message</Form.Label>
										<Form.Control
											name="message"
											className="messageControl"
											as="textarea"
											required></Form.Control>
									</Form.Group>

									<div className="button-container">
										<Button
											ariaLabel="submit"
											text="Submit"
											color="black"
											onClick={() => handleSubmit}
											type="submit"
										/>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default GetInvolved;
