import React, { useEffect, useState } from "react";
import { ReactComponent as JournalSvg } from "../../../imgs/icons/journal.svg";
import { ReactComponent as Arrow } from "../../../imgs/icons/arrow.svg";
import PreviewTile from "../../PreviewTile";
import Button from "../../Button";

import JournalData from "../../../JSON/JournalData";
import { Journal } from "../../../JSON/schemas";
import { useHistory } from "react-router";
import Subscribe from "../../Subscribe/Subscribe";

function JournalTile() {
	const history = useHistory();
	const [journals, setJournals] = useState<Journal[]>([]);
	const text: any = (
		<span>
			See all posts &nbsp;
			<Arrow stroke="white"></Arrow>
		</span>
	);
	useEffect(() => {
		const journalsTemp: Journal[] = [];
		let displayNum = 4;
		if (JournalData.length < 4) {
			displayNum = JournalData.length;
		}
		for (let i = 0; i < displayNum; i++) {
			journalsTemp.push(JournalData[i]);
		}
		setJournals(journalsTemp);
	}, []);
	return (
		<div className="journal-tile">
			<div className="content mx-auto">
				<div className="box">
					<div className="justify-content-center d-flex">
						<JournalSvg className="journal-icon" />
					</div>
					<h2>Journal</h2>
					<p>
						Our journal space is an area for our Mindscape community
						to share real-life experiences and how they are
						utilising skills effectively in their everyday lives as
						well as for experts to share the latest wellbeing tips &
						advice.
					</p>
				</div>
				{journals.length <= 4 && journals.length > 0 && (
					<div className="row justify-content-center">
						{journals.map(journal => {
							return (
								<div
									className="col-xl-3 col-lg-5 col-sm-10 justify-content-center d-flex"
									key={`journals-${journal.id}`}>
									<PreviewTile
										title={journal.title}
										previewDisplay={journal.image}
										type="journal"
										color="purple"
										onClick={() =>
											history.push(
												`/journal/${journal.id}`
											)
										}
										noMargin={true}
									/>
								</div>
							);
						})}
					</div>
				)}
				<div className="button-container">
					<Button
						ariaLabel="all-journals"
						text={text}
						color="purple"
						classOverride="journal-button"
						onClick={() => history.push("/journal")}
					/>
				</div>

				<Subscribe />
			</div>
		</div>
	);
}

export default JournalTile;
