import { AllEmotions } from "./schemas";

const EmotionData: AllEmotions = {
	happy: {
		level1: [
			{
				id: "12",
				type: "skill",
			},
			{
				id: "30",
				type: "skill",
			},
			{
				id: "20",
				type: "skill",
			},
			{
				id: "31",
				type: "skill",
			},
			{
				id: "24",
				type: "skill",
			},
			{
				id: "15",
				type: "skill",
			},
			{
				id: "35",
				type: "skill",
			},
			{
				id: "36",
				type: "skill",
			},
		],
		level2: [
			{
				id: "4",
				type: "skill",
			},
			{
				id: "37",
				type: "skill",
			},
			{
				id: "13",
				type: "skill",
			},
			{
				id: "29",
				type: "skill",
			},
			{
				id: "34",
				type: "skill",
			},
			{
				id: "21",
				type: "skill",
			},
			{
				id: "22",
				type: "skill",
			},
			{
				id: "11",
				type: "skill",
			},
		],
		level3: [
			{
				id: "13",
				type: "skill",
			},
			{
				id: "28",
				type: "skill",
			},
			{
				id: "38",
				type: "skill",
			},
		],
	},
	sad: {
		level1: [
			{
				id: "4",
				type: "skill",
			},
			{
				id: "17",
				type: "skill",
			},
			{
				id: "13",
				type: "skill",
			},
			{
				id: "33",
				type: "skill",
			},
			{
				id: "9",
				type: "skill",
			},
			{
				id: "15",
				type: "skill",
			},
		],
		level2: [
			{
				id: "12",
				type: "skill",
			},
			{
				id: "28",
				type: "skill",
			},
			{
				id: "34",
				type: "skill",
			},
			{
				id: "31",
				type: "skill",
			},
			{
				id: "10",
				type: "skill",
			},
			{
				id: "32",
				type: "skill",
			},
			{
				id: "36",
				type: "skill",
			},
		],
		level3: [
			{
				id: "30",
				type: "skill",
			},
			{
				id: "20",
				type: "skill",
			},
			{
				id: "23",
				type: "skill",
			},
			{
				id: "24",
				type: "skill",
			},
			{
				id: "35",
				type: "skill",
			},
		],
	},
	guilt: {
		level1: [
			{
				id: "37",
				type: "skill",
			},
			{
				id: "9",
				type: "skill",
			},
			{
				id: "23",
				type: "skill",
			},
		],
		level2: [
			{
				id: "17",
				type: "skill",
			},
			{
				id: "19",
				type: "skill",
			},
			{
				id: "11",
				type: "skill",
			},
			{
				id: "15",
				type: "skill",
			},
		],
		level3: [
			{
				id: "27",
				type: "skill",
			},
			{
				id: "30",
				type: "skill",
			},
			{
				id: "20",
				type: "skill",
			},
			{
				id: "31",
				type: "skill",
			},
			{
				id: "24",
				type: "skill",
			},
			{
				id: "25",
				type: "skill",
			},
		],
	},
	love: {
		level1: [
			{
				id: "12",
				type: "skill",
			},
			{
				id: "4",
				type: "skill",
			},
			{
				id: "19",
				type: "skill",
			},
			{
				id: "30",
				type: "skill",
			},
			{
				id: "31",
				type: "skill",
			},
			{
				id: "10",
				type: "skill",
			},
			{
				id: "24",
				type: "skill",
			},
			{
				id: "35",
				type: "skill",
			},
		],
		level2: [
			{
				id: "4",
				type: "skill",
			},
			{
				id: "37",
				type: "skill",
			},
			{
				id: "18",
				type: "skill",
			},
			{
				id: "33",
				type: "skill",
			},
			{
				id: "29",
				type: "skill",
			},
			{
				id: "34",
				type: "skill",
			},
			{
				id: "15",
				type: "skill",
			},
		],
		level3: [
			{
				id: "37",
				type: "skill",
			},
			{
				id: "18",
				type: "skill",
			},
			{
				id: "28",
				type: "skill",
			},
			{
				id: "9",
				type: "skill",
			},
		],
	},
	anger: {
		level1: [
			{
				id: "17",
				type: "skill",
			},
			{
				id: "18",
				type: "skill",
			},
			{
				id: "19",
				type: "skill",
			},
			{
				id: "21",
				type: "skill",
			},
			{
				id: "9",
				type: "skill",
			},
		],
		level2: [
			{
				id: "22",
				type: "skill",
			},
			{
				id: "10",
				type: "skill",
			},
			{
				id: "23",
				type: "skill",
			},
			{
				id: "24",
				type: "skill",
			},
			{
				id: "20",
				type: "skill",
			},
			{
				id: "26",
				type: "skill",
			},
		],
		level3: [
			{
				id: "20",
				type: "skill",
			},
			{
				id: "26",
				type: "skill",
			},
			{
				id: "24",
				type: "skill",
			},
			{
				id: "25",
				type: "skill",
			},
			{
				id: "16",
				type: "skill",
			},
		],
	},
	jealous: {
		level1: [
			{
				id: "28",
				type: "skill",
			},
			{
				id: "29",
				type: "skill",
			},
			{
				id: "31",
				type: "skill",
			},
			{
				id: "32",
				type: "skill",
			},
		],
		level2: [
			{
				id: "12",
				type: "skill",
			},
			{
				id: "17",
				type: "skill",
			},
			{
				id: "13",
				type: "skill",
			},
			{
				id: "19",
				type: "skill",
			},
			{
				id: "31",
				type: "skill",
			},
			{
				id: "32",
				type: "skill",
			},
		],
		level3: [
			{
				id: "27",
				type: "skill",
			},
			{
				id: "19",
				type: "skill",
			},
			{
				id: "30",
				type: "skill",
			},
			{
				id: "10",
				type: "skill",
			},
			{
				id: "24",
				type: "skill",
			},
			{
				id: "25",
				type: "skill",
			},
		],
	},
	shame: {
		level1: [
			{
				id: "12",
				type: "skill",
			},
			{
				id: "17",
				type: "skill",
			},
			{
				id: "33",
				type: "skill",
			},
			{
				id: "9",
				type: "skill",
			},
		],
		level2: [
			{
				id: "17",
				type: "skill",
			},
			{
				id: "27",
				type: "skill",
			},
			{
				id: "19",
				type: "skill",
			},
			{
				id: "34",
				type: "skill",
			},
			{
				id: "31",
				type: "skill",
			},
			{
				id: "10",
				type: "skill",
			},
			{
				id: "16",
				type: "skill",
			},
		],
		level3: [
			{
				id: "30",
				type: "skill",
			},
			{
				id: "20",
				type: "skill",
			},
			{
				id: "10",
				type: "skill",
			},
			{
				id: "23",
				type: "skill",
			},
			{
				id: "24",
				type: "skill",
			},
		],
	},
	fear: {
		level1: [
			{
				id: "17",
				type: "skill",
			},
			{
				id: "19",
				type: "skill",
			},
			{
				id: "34",
				type: "skill",
			},
			{
				id: "21",
				type: "skill",
			},
			{
				id: "9",
				type: "skill",
			},
		],
		level2: [
			{
				id: "27",
				type: "skill",
			},
			{
				id: "19",
				type: "skill",
			},
			{
				id: "21",
				type: "skill",
			},
			{
				id: "10",
				type: "skill",
			},
			{
				id: "11",
				type: "skill",
			},
			{
				id: "24",
				type: "skill",
			},
		],
		level3: [
			{
				id: "30",
				type: "skill",
			},
			{
				id: "20",
				type: "skill",
			},
			{
				id: "23",
				type: "skill",
			},
			{
				id: "25",
				type: "skill",
			},
			{
				id: "16",
				type: "skill",
			},
		],
	},
};

export default EmotionData;
