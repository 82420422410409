import React from "react";
import { ReactComponent as HappyFace } from "../../imgs/icons/happy-face.svg";

import founder from "../../imgs/images/deena.jpg";

function AboutPage() {
	return (
		<div className="about-page">
			<div className="about-mindscape">
				<div className="content mx-auto">
					<div className="row justify-content-center">
						<div className="col-lg-5 order-lg-12 justify-content-center d-flex my-auto">
							<HappyFace className="happy-face" />
						</div>
						<div className="col-lg-5 order-lg-1 justify-content-center d-flex my-auto">
							<div className="box">
								<h1>About Mindscape</h1>
								<p>
									Navigating your mind can feel like a
									complicated journey but it doesn't have to
									be. We're dedicated to helping each other
									become the best version of ourselves by
									sharing insight and tools to help you stay
									on track.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="feeling-tile">
				<div className="shame"></div>
				<div className="anger"></div>
				<div className="content mx-auto">
					<div className="box">
						<h2>
							Whatever you're feeling, we're here to help you.
						</h2>
						<p>
							Our vision is to empower a whole community with the
							skills and tools needed to thrive in life. We know
							that life situations can throw us off track and make
							it difficult to regulate our emotions which can
							often leave us feeling isolated and stuck on our
							journey. We believe everyone should have free access
							to core skills that can help with emotion regulation
							and enable individuals not to just survive but
							thrive, in all areas of their life. By pulling
							everything together in one place we hope it makes it
							easier for individuals to navigate and access what
							they need. Mindscape incorporates different ways of
							learning so that wherever you are on your journey
							there is something for you.
						</p>
					</div>
				</div>
			</div>

			<div className="founder-tile">
				<div className="guilt"></div>
				<div className="content mx-auto">
					<h2>Meet the founder</h2>
					<div className="justify-content-center d-flex">
						<img src={founder} alt="mental-health-exercise" />
					</div>
					<p className="p2">
						After successfully beating self-harm through 15 years of
						psychological support our Founder Deena became motivated
						to establish a community that was vitally needed. A
						community whereby knowledge, practical skills and tools
						could be shared.
					</p>
					<p className="p2">
						“Not everyone has a mental illness, but we all have
						emotions. We can all benefit from empowering ourselves
						with the knowledge and skills to build a toolbox that
						enables us to navigate are own minds and support
						others”.
					</p>
					<p className="p3">
						Deena speaks publicly about her experiences and has
						written articles and given interviews on TV, including
						BBC Breakfast to raise awareness and campaign for
						greater mental health support. Graduating with a BSc in
						Psychology from Durham, Deena went on to work within the
						NHS before transitioning careers to Management
						Consultancy where she continues to work today.
					</p>
					<p className="p2">
						Deena is passionate about educating and empowering
						individuals and creating a global community of support
						so that everyone can succeed on their wellbeing journey
						no matter where they are at.
					</p>
				</div>
			</div>
		</div>
	);
}

export default AboutPage;
