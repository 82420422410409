import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

type Props = {
	icon: IconDefinition;
	onClick: () => void;
};

export default ({ icon, onClick }: Props) => {
	return (
		<div className="icon-button" onClick={onClick}>
			<FontAwesomeIcon icon={icon} />
		</div>
	);
};
