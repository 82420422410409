import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Logo } from "../../imgs/mindscape-logo.svg";
import { ReactComponent as Call } from "../../imgs/icons/call.svg";
import IconButton from "../IconButton";
import Button from "../Button/Button";

export default () => {
	const [open, setOpen] = useState(false);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	function navbarEmergencyButton() {
		setOpen(false);
		setShow(true);
	}

	const num1: JSX.Element = (
		<span>
			<a href="tel:+44999">
				<Call /> &nbsp;999
			</a>
		</span>
	);

	const num2: JSX.Element = (
		<span>
			<a href="tel:+44116-123">
				<Call /> &nbsp; Samaritans
			</a>
		</span>
	);

	const num3: JSX.Element = (
		<span>
			<a href="tel:+440800-58-58-58">
				<Call /> &nbsp;CALM
			</a>
		</span>
	);

	const num4: JSX.Element = (
		<span>
			<a href="tel:+440800-068-41-41">
				<Call /> &nbsp;Papyrus
			</a>
		</span>
	);
	return (
		<>
			{!open && (
				<div className="navbar">
					<div className="background">
						{/* // TODO: Fix this when link is added it's not aligned */}
						<Link to="/" aria-label="home">
							<Logo
								className="logo my-auto pl-4"
								style={{ cursor: "pointer" }}
								onClick={() =>
									window.scrollTo({
										behavior: "smooth",
										top: 0,
									})
								}
							/>
						</Link>
						<IconButton
							icon={faBars}
							onClick={() => setOpen(true)}
						/>
					</div>
					<div className="emerg-butt">
						<Button
							ariaLabel="emergency-help"
							text="Emergency Help"
							color="white"
							classOverride="mobile-navbar"
							onClick={handleShow}
						/>
					</div>
				</div>
			)}
			{open && (
				<div className="navbar">
					<div className="background">
						<Link to="/" aria-label="home">
							<Logo
								className="logo my-auto pl-4"
								style={{ cursor: "pointer" }}
								onClick={() =>
									window.scrollTo({
										behavior: "smooth",
										top: 0,
									})
								}
							/>
						</Link>
						<IconButton
							icon={faTimes}
							onClick={() => setOpen(false)}
						/>
					</div>
					<div className="emerg-butt">
						<Button
							ariaLabel="emergency-help"
							text="Emergency Help"
							color="white"
							classOverride="mobile-navbar"
							onClick={() => navbarEmergencyButton()}
						/>
					</div>
					<div className="items d-flex justify-content-center">
						<div className="column pt-3">
							<Link to="/" aria-label="home" className="heading">
								Home
							</Link>
							<Link
								to="/about"
								aria-label="about-us"
								className="title">
								About us
							</Link>
							<Link
								to="/journal"
								aria-label="Journal"
								className="title">
								Journal
							</Link>
							<Link
								to="/mindful-conversations"
								aria-label="Mindful Conversations"
								className="title">
								Mindful Conversations
							</Link>
							<Link
								to="/skills-toolkit"
								aria-label="Skills Toolkit"
								className="title">
								Skills Toolkit
							</Link>
							<a
								href="/get-involved"
								className="title"
								aria-label="Get Involved">
								Get Involved
							</a>
							<Link
								to="/mind-mapper"
								aria-label="mind-mapper"
								className="title">
								Mind Mapper
							</Link>
						</div>
					</div>
				</div>
			)}

			<Modal
				show={show}
				onHide={handleClose}
				dialogClassName="help-modal">
				<Modal.Header>
					<Modal.Title>
						If your life is at risk right now or you need urgent
						help
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						If you feel like you might attempt suicide, have
						seriously harmed yourself, or are having thoughts of
						harming yourself, please seek urgent medical help.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						ariaLabel="mind-mapper"
						text={num1}
						color="white"
						classOverride={"phone-btn"}
					/>
					<Button
						ariaLabel="mind-mapper"
						text={num2}
						color="white"
						classOverride={"phone-btn"}
					/>
					<Button
						ariaLabel="mind-mapper"
						text={num3}
						color="white"
						classOverride={"phone-btn"}
					/>
					<Button
						ariaLabel="mind-mapper"
						text={num4}
						color="white"
						classOverride={"phone-btn"}
					/>
				</Modal.Footer>
				{/* <div className="row justify-content-center d-flex">
					<Button
						ariaLabel="mind-mapper"
						text="Close window"
						color="white"
						classOverride="close-window"
						onClick={handleClose}
					/>
				</div> */}
			</Modal>
		</>
	);
};
