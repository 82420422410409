import React from "react";
import Journal1 from "./Journal-1";
import Journal69 from "./Journal-69";

const Components: { [key: string]: () => JSX.Element } = {
	"Journal-1": Journal1,
	"Journal-69": Journal69,
};

export default (id: string) => {
	if (Components[`Journal-${id}`] !== undefined) {
		return React.createElement(Components[`Journal-${id}`]);
	}
	return React.createElement(() => <div>Error loading journal</div>);
};
