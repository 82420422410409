import * as React from "react";
import mhexercise from "../../../imgs/images/mh-exercise.jpg";

function MentalExerciseTile() {
	return (
		<div className="exercise-tile">
			<div className="content mx-auto">
				<div className="row">
					<div className="col-lg-6 col-sm-12 justify-content-end d-flex">
						<img src={mhexercise} alt="mental-health-exercise" />
					</div>
					<div className="col-lg-6 col-sm-12 my-auto">
						<h2>
							Free practical skills and advice to empower you on
							your wellbeing journey
						</h2>
						<p>
							Mindscape is committed to ensuring everyone has
							access to the skills and tools needed to help them
							thrive in life. By providing our users with
							practical skills and tools we hope that every
							individual can feel empowered to create a
							personalised toolbox that works for them. At
							Mindscape we appreciate everyone is unique and on a
							different journey. In addition to our interactive
							Mind Mapper toolkit we will also share the latest
							advice on how to implement skills through our video
							and podcast channel Mindful Conversations and our
							Journal area.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MentalExerciseTile;
