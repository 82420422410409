import React from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import useWindowSize from "../../hooks/useWindowSize";

export default () => {
	const size = useWindowSize();

	if (size.width !== undefined && size.width < 992) {
		return <Mobile />;
	} else {
		return <Desktop />;
	}
};
