import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../imgs/mindscape-logo.svg";
import { ReactComponent as Call } from "../../imgs/icons/call.svg";
import Button from "../Button";

function NavBar() {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// TODO: make whole button clickable for phoning
	const num1: JSX.Element = (
		<span>
			<a href="tel:+44999">
				<Call /> &nbsp;999
			</a>
		</span>
	);

	const num2: JSX.Element = (
		<span>
			<a href="tel:+44116-123">
				<Call /> &nbsp; Samaritans
			</a>
		</span>
	);

	const num3: JSX.Element = (
		<span>
			<a href="tel:+440800-58-58-58">
				<Call /> &nbsp;CALM
			</a>
		</span>
	);

	const num4: JSX.Element = (
		<span>
			<a href="tel:+440800-068-41-41">
				<Call /> &nbsp;Papyrus
			</a>
		</span>
	);
	return (
		<>
			<div className="navbar">
				<div className="background d-flex justify-content-center">
					<div className="items my-auto">
						<div className="row">
							<div className="col-2 my-auto d-flex justify-content-end nav-logo">
								<Link to="/" aria-label="home">
									<Logo
										className="logo"
										style={{ cursor: "pointer" }}
										onClick={() =>
											window.scrollTo({
												behavior: "smooth",
												top: 0,
											})
										}
									/>
								</Link>
							</div>
							<div className="col-7 my-auto nav-links">
								<div className="row mx-auto">
									<div className="title-col d-flex justify-content-center">
										<Link
											to="/about"
											aria-label="about-us"
											className="title">
											About us
										</Link>
									</div>
									<div className="title-col d-flex justify-content-center">
										<Link
											to="/journal"
											aria-label="Journal"
											className="title">
											Journal
										</Link>
									</div>
									<div className="title-col d-flex justify-content-center">
										<Link
											to="/mindful-conversations"
											aria-label="Mindful Conversations"
											className="title">
											Mindful Conversations
										</Link>
									</div>
									<div className="title-col d-flex justify-content-center">
										<Link
											to="/skills-toolkit"
											aria-label="Skills Toolkit"
											className="title">
											Skills Toolkit
										</Link>
									</div>
									<div className="title-col d-flex justify-content-start">
										<Link
											to="/get-involved"
											aria-label="Get Involved"
											className="title">
											Get Involved
										</Link>
									</div>
								</div>
							</div>
							<div className="col-3 my-auto nav-help">
								<div className="row">
									<div className="col">
										<Link
											to="/mind-mapper"
											aria-label="mind-mapper">
											<Button
												ariaLabel="mind-mapper"
												text="Mind Mapper"
												color="yellow-white"
												onClick={() =>
													window.scrollTo({
														behavior: "smooth",
														top: 0,
													})
												}
											/>
										</Link>
									</div>
									<div className="col">
										<Button
											ariaLabel="emergency-help"
											text="Emergency Help"
											color="white"
											onClick={handleShow}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				show={show}
				onHide={handleClose}
				dialogClassName="help-modal">
				<Modal.Header>
					<Modal.Title>
						If your life is at risk right now or you need urgent
						help
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						If you feel like you might attempt suicide, have
						seriously harmed yourself, or are having thoughts of
						harming yourself, please seek urgent medical help.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button ariaLabel="mind-mapper" text={num1} color="white" />
					<Button ariaLabel="mind-mapper" text={num2} color="white" />
					<Button ariaLabel="mind-mapper" text={num3} color="white" />
					<Button ariaLabel="mind-mapper" text={num4} color="white" />
				</Modal.Footer>
				<div className="row justify-content-center d-flex">
					<Button
						ariaLabel="mind-mapper"
						text="Close window"
						color="white"
						classOverride="close-window"
						onClick={handleClose}
					/>
				</div>
			</Modal>
		</>
	);
}

export default NavBar;
