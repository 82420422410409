import React, { useState } from "react";
import NavBar from "../NavBar";
import Button from "../Button";
import FeelingDisplay from "./FeelingDisplay";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../imgs/icons/arrow.svg";
import { find } from "lodash";
import jsonData from "../../JSON/moods.json";

function FeelingSelect() {
	const text: JSX.Element = (
		<span>
			Next &nbsp;<Arrow stroke="#262626"></Arrow>
		</span>
	);

	const [clicked, setClicked] = useState(false);
	const [selected, setSelected] = useState("");

	function moodClicked(mood: string) {
		setClicked(true);
		setSelected(mood);
	}

	function getDescription() {
		const moodObject = find(jsonData, ["name", selected]);
		return <p>{moodObject?.description}</p>;
	}

	return (
		<>
			<NavBar></NavBar>
			{!clicked && (
				<div className="mind-mapper feeling-select">
					<div className="content mx-auto">
						<div className="box">
							<h2 className="mx-auto">
								First, how are you feeling today?
							</h2>
							<p>
								Which emotion do you need help with right now?
							</p>
							{/* TODO: FIX THIS */}
							<p className="p2">
								We know emotions can be complex, which is why we
								have selected eight core emotions. Click on the
								emojis to understand if that is the emotion you
								are experiencing right now.
							</p>

							<div className="row mx-auto">
								<div
									className="col-lg-3 col-sm-6 justify-content-center d-flex mx-auto"
									onClick={() => moodClicked("happy")}>
									<FeelingDisplay
										mood="happy"
										selected={false}
									/>
								</div>
								<div
									className="col-lg-3 col-sm-6 justify-content-center d-flex mx-auto"
									onClick={() => moodClicked("sad")}>
									<FeelingDisplay
										mood="sad"
										selected={false}
									/>
								</div>
								<div
									className="col-lg-3 col-sm-6 justify-content-center d-flex mx-auto"
									onClick={() => moodClicked("guilt")}>
									<FeelingDisplay
										mood="guilt"
										selected={false}
									/>
								</div>
								<div
									className="col-lg-3 col-sm-6 justify-content-center d-flex mx-auto"
									onClick={() => moodClicked("love")}>
									<FeelingDisplay
										mood="love"
										selected={false}
									/>
								</div>
							</div>
							<div className="row mx-auto">
								<div
									className="col-lg-3 col-sm-6 justify-content-center d-flex mx-auto"
									onClick={() => moodClicked("anger")}>
									<FeelingDisplay
										mood="anger"
										selected={false}
									/>
								</div>
								<div
									className="col-lg-3 col-sm-6 justify-content-center d-flex mx-auto"
									onClick={() => moodClicked("jealous")}>
									<FeelingDisplay
										mood="jealous"
										selected={false}
									/>
								</div>
								<div
									className="col-lg-3 col-sm-6 justify-content-center d-flex mx-auto"
									onClick={() => moodClicked("shame")}>
									<FeelingDisplay
										mood="shame"
										selected={false}
									/>
								</div>
								<div
									className="col-lg-3 col-sm-6 justify-content-center d-flex mx-auto"
									onClick={() => moodClicked("fear")}>
									<FeelingDisplay
										mood="fear"
										selected={false}
									/>
								</div>
							</div>
						</div>
						<div className="justify-content-center d-flex">
							<Link
								to="/mind-mapper"
								aria-label="home"
								className="redirect">
								Back
							</Link>
						</div>
					</div>
				</div>
			)}

			{clicked && (
				<div className="mind-mapper feeling-clicked">
					<div className="content mx-auto">
						<div className="box">
							<h2 className="mx-auto">
								First, how are you feeling today?
							</h2>
							<p>
								Which emotion do you need help with right now?
							</p>

							<div className="mood-col justify-content-center d-flex mx-auto">
								<FeelingDisplay
									mood={selected}
									selected={true}
								/>
							</div>

							<div className="row mx-auto">
								{getDescription()}
							</div>

							<div className="next-button justify-content-center d-flex">
								<Link to={"/mind-mapper/rate/" + selected}>
									<Button
										ariaLabel="next"
										text={text}
										color="yellow"
										onClick={() =>
											window.scrollTo({
												behavior: "smooth",
												top: 0,
											})
										}
									/>
								</Link>
							</div>

							<div className="justify-content-center d-flex">
								<span
									aria-label="home"
									className="redirect"
									onClick={() => setClicked(false)}>
									Back
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default FeelingSelect;
