import { Journal } from "./schemas";

const JournalData: Journal[] = [
	{
		id: "1",
		title: "Britain We Have a Problem",
		image: "journal-image-1.jpg",
		author: "Lucy Puttergill, Coach and Breathwork Specialist ",
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ultrices sit amet purus at dignissim.",
	},
];

export default JournalData;
