import React from "react";
import { ReactComponent as Arrow } from "../../../imgs/icons/arrow.svg";
import { ReactComponent as HappyFace } from "../../../imgs/icons/happy-face.svg";
import Button from "../../Button";
import { useHistory } from "react-router-dom";

function AboutUsTile() {
	const history = useHistory();
	const text: JSX.Element = (
		<span>
			Who are Mindscape? &nbsp;
			<Arrow stroke="black"></Arrow>
		</span>
	);
	return (
		<div className="about-tile">
			<div className="content mx-auto">
				<div className="row">
					<div className="col-lg-6 order-lg-12 justify-content-center d-flex my-auto">
						<HappyFace className="happy-face" />
					</div>
					<div className="col-lg-6 order-lg-1 justify-content-center d-flex">
						<div className="box">
							<h2>Skills used and tested by us</h2>
							<p>
								We understand how hard it is to make changes.
								That's why all the skills shared are practical
								and evidence based and are drawn from our teams
								direct experiences of different psychological
								therapies that have been successful in creating
								long-lasting positive change. We encourage every
								individual to explore Mindscape and find their
								own set of tools that work for them. As you
								grow, your toolbox may change with time, so
								don't forget to check-in and keep learning and
								empowering yourself with new skills.
							</p>
							<Button
								ariaLabel="about-us"
								text={text}
								color="yellow"
								classOverride="about-button"
								onClick={() => history.push("/about")}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutUsTile;
