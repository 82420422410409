import React from "react";
import NavBar from "../NavBar";
import JournalTile from "../../components/HomePage/Journal";
import Footer from "../../components/Footer";
import AboutPage from "./AboutPage";

function AboutMain() {
	return (
		<>
			<div className="container-flex" style={{ width: "100vw" }}>
				<NavBar />
				<AboutPage />
				<JournalTile />
				<Footer />
			</div>
		</>
	);
}

export default AboutMain;
