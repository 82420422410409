export default () => {
	return (
		<>
			<p>Hello there</p>

			<h2>General Kenobi</h2>

			<p>
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit
				suscipit amet nulla veritatis, saepe doloribus, repellendus
				cumque voluptatum iusto temporibus fugiat dolor unde debitis
				libero accusantium natus aliquid, tenetur sint? Lorem ipsum
				dolor sit amet consectetur adipisicing elit. Repudiandae et est
				deserunt unde, voluptatibus amet nihil illo, quaerat nostrum
				alias, reprehenderit asperiores reiciendis. Hic dolore repellat
				tempore distinctio eligendi et.
			</p>

			<p>
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit
				suscipit amet nulla veritatis, saepe doloribus, repellendus
				cumque voluptatum iusto temporibus fugiat dolor unde debitis
				libero accusantium natus aliquid, tenetur sint? Lorem ipsum
				dolor sit amet consectetur adipisicing elit. Repudiandae et est
				deserunt unde, voluptatibus amet nihil illo, quaerat nostrum
				alias, reprehenderit asperiores reiciendis. Hic dolore repellat
				tempore distinctio eligendi et.
			</p>
		</>
	);
};
