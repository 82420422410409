import React from "react";
import Button from "../Button";
import NavBar from "../NavBar";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../imgs/icons/arrow.svg";

function MindMapper() {
	const text: JSX.Element = (
		<span>
			Get started &nbsp;<Arrow stroke="#262626"></Arrow>
		</span>
	);

	return (
		<>
			<NavBar></NavBar>
			<div className="mind-mapper">
				<div className="content mx-auto d-flex">
					<div className="shame"></div>
					<div className="sad"></div>
					<div className="anger"></div>
					<div className="happy"></div>
					<div className="box mx-auto my-auto">
						<h1>Mind Mapper</h1>
						<p>
							This interactive tool aims to guide you to
							effectively manage how you are feeling and your
							emotional response. It takes 5 minutes and provides
							you with recommendations of skills alongside
							worksheets to help you practice in becoming an
							expert in navigating your mind.
						</p>
						<div className="justify-content-center d-flex">
							<Link
								to="/mind-mapper/select"
								aria-label="feelings">
								<Button
									ariaLabel="emergency-help"
									text={text}
									color="yellow"
									onClick={() =>
										window.scrollTo({
											behavior: "smooth",
											top: 0,
										})
									}
								/>
							</Link>
						</div>
						<div className="justify-content-center d-flex">
							<Link to="/" aria-label="home" className="redirect">
								Back home
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default MindMapper;
