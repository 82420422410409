import React from "react";
import { Switch, Route } from "react-router-dom";
import Main from "./layout/Main";
import AboutMain from "./components/AboutPage";
import MindMapper from "./components/MindMapper";
import JournalEntry from "./components/JournalEntry";
import EmotionResources from "./components/EmotionResources";
import FeelingSelect from "./components/MindMapper/FeelingSelect";
import RateFeeling from "./components/MindMapper/RateFeeling";
import SkillsPage from "./components/ContentSelectPage/SkillsPage";
import MindfulPage from "./components/ContentSelectPage/MindfulPage";
import JournalPage from "./components/ContentSelectPage/JournalPage";
import GetInvolved from "./components/GetInvolved";

function App() {
	return (
		<Switch>
			<Route path="/" exact={true} component={Main} />
			<Route path="/about" exact={true} component={AboutMain} />
			<Route path="/mind-mapper" exact={true} component={MindMapper} />
			<Route path="/get-involved" exact={true} component={GetInvolved} />
			<Route
				path="/mind-mapper/select"
				exact={true}
				component={FeelingSelect}
			/>
			<Route
				path="/mind-mapper/rate/:mood"
				exact={true}
				component={RateFeeling}
			/>
			<Route
				path="/mind-mapper/:emotion/:intensity"
				exact={true}
				component={EmotionResources}
			/>
			<Route path="/journal" exact={true} component={JournalPage} />
			<Route path="/skills-toolkit" exact={true} component={SkillsPage} />
			<Route
				path="/mindful-conversations"
				exact={true}
				component={MindfulPage}
			/>
			<Route
				path="/journal/:journalId"
				exact={true}
				component={JournalEntry}
			/>
		</Switch>
	);
}

export default App;
