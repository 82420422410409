export default () => {
	return (
		<>
			<p>
				I feel like I may well start sounding like a broken record if I
				carry on talking like this. That said, I’m willing to take the
				risk because this shit’s important. Like really important. So
				buckle up kids, she’s going in for it.
			</p>
			<p>WE HAVE TO STOP DISCRIMINATING AGAINST EMOTIONS.</p>
			<p>
				Before your mouse moves to the little cross in the corner
				thinking, what an earth is this woman on about, give me the
				grace of a couple of lines. Just a couple, I promise, it’s worth
				it.
			</p>
			<p>
				We need to talk about the British stiff upper lip. Like really
				talk about.
			</p>
			<p>
				Since I started on this wiggly old path of self improvement,
				I’ve come into much closer contact with a lot of emotions than I
				had for pretty much the entire of the rest of my existence on
				this planet. I’ve cried, a lot. I’ve screamed into pillows. I’ve
				punched pillows. I’ve laughed hysterically. I’ve danced in
				ecstasy. Sometimes, I’ve done all of these things within the
				space of 24 hours.
			</p>
			<p>
				Now, tell me, honestly — what is your reaction to me telling you
				about my tears? about my anger? Let me ask you this… is there
				resistance to it? Do you think for a second ‘oh, no, is she ok?’
				or a ‘she’s lost the plot’, or ‘wow this is intense’? If you do,
				can I ask you…why?
			</p>
			<p>
				If someone had asked me the same question about 3 years ago, I
				probably would have said yes, yes and yes. I was chronically
				unemotional. To be honest, I wore it a little bit like a badge
				of honour. Somehow, I think I thought I was ‘strong’ because I
				never cried. I thought I was strong because I was always cool,
				calm and collected. Always moderated in my responses.
				Unflappable. It became part of my identity in a way, I liked the
				fact that I believed nothing could ever harm me, that I could
				walk through flames and come out unscathed. I felt strong. I
				felt in control. The reality though, was that I was gravely out
				of touch with myself and chose only to show the ‘positive’
				emotions because those were the ones that people liked to be
				around.
			</p>
			<p>
				Why is it we only like to be around joy? Why is it that we
				fetishise happiness? Why is it that people feel they have to cry
				in private but have no issue with laughing in public? Is it
				because that’s just what we do, or is it because we
				subconsciously believe that joy and laughter are ‘attractive’
				and sadness and grief are ‘unattractive’? That an expression of
				joy is ‘positive’ whilst an expression of sadness is ‘negative’?
				I’ll ask you this, why? Why do you choose to believe this?
			</p>
			<p>
				Let’s take a little look at what an emotion actually is.. the
				word emotion comes from the latin ‘emotere’ which literally
				means energy in motion. That’s what an emotion is, energy in
				motion. Sometimes, if you get quiet enough, you might be able to
				sense that that’s what it is. The tingling in the chest, the
				heaviness in the pit of the stomach, the lump in the throat —
				all just examples of the ways in which energy moves through the
				body in the form of emotion. Only when we put labels on this
				movement of energy, such as sadness or happiness do they become
				feelings with their associated positive and negative labels.
			</p>
			<p>
				For those of you holding on to your stiff upper lip— listen to
				this.. the parts of the brain which manage our emotions are
				actually considered to have executive power over the brain. As
				in, they are actually more powerful than the logical side you
				hold so tightly to. They strongly influence decision making,
				thought processes, memories and our experience of the present
				moment. Because of this, our ability to healthily manage
				emotional energy is vital in our pursuit of wellbeing, as well
				as in our pursuit of being able to exist effectively in the
				world. The more we suppress this energy in motion, or only allow
				certain types of energy in emotion to be expressed, the more
				likely we are to suffer from chronic stress and the associated
				stress related illnesses. Chronic stress is, in fact, a symptom
				of built up emotional energy in the body.
			</p>
			<p>
				Humans are built to emote. It is a beautiful part of the body’s
				design. The expression of emotion allows the body to come back
				to homeostasis and to come back to a place of peace and rest.
				The more we suppress certain forms of emotion, the more we are
				suffocating ourselves from the peace which we are all so
				desperately craving. Crying, in fact, is so wonderful that it
				releases oxytocin and endogenous opioids which in turn soothe
				the nervous system and basically make us feel better. We cry for
				a reason. We are built to cry for a reason: we need to stop
				coddling tears and allow them to flow in the way they are
				designed. Our bodies ask us to e-mote for a reason, because it
				keeps us healthy. We have to stop ignoring what our bodies are
				asking of us and start to notice what it is we feel, and allow
				that emotion to be expressed in a healthy way. We have to allow
				this energy to flow.
			</p>
			<p>
				I became interested in the idea that we could suppress emotion
				when I first experienced circular breathwork (which I
				subsequently trained in). Breathing in a specific way for
				extended periods of time strangely enough brings us into close
				contact with repressed emotional content (another smart thing
				our body does). What I mean by this is that through breathwork,
				I came in very close contact with a tonne of fear, grief,
				sadness, joy and anger that I had no idea I was harbouring
				inside. Like no idea. One minute I was lying on the mat
				breathing away, the next I was screaming into a pillow. As I
				said, I thought I was chronically unemotional, well I’ll tell
				you this: nobody is chronically unemotional. Not. One. Person.
				Nobody naturally has a stiff upper lip. What we have is a
				society filled with people who have suppressed so much of their
				emotional content that our bodies are bursting at the seams.
				Stress related illnesses are rife and seem to be increasing in
				number by the day, we’re on the verge of the worst mental health
				crisis we’ve probably ever faced and we are STILL telling people
				to wipe away their tears because…. ‘oh, please don’t cry, it
				makes me sad when you cry’.
			</p>
			<p>
				Let me reframe this: please do cry. please cry as much as you
				need to. please scream. please grab a pillow and scream into it
				as loudly as you need to. please get angry, take a pillow and
				punch it as hard as you need to. please. all I’m asking is that
				you allow your bodies innate wisdom to DO ITS THING and stop
				overriding it just because someone, somewhere who has nothing to
				do with your life or your health decided that it would be better
				if we stopped crying so much and created a narrative around
				crying being a ‘bad’ thing which in turn became a socially
				accepted truth. We desperately need to encourage the healthy
				expression of all emotion, every last drop of it. Welcome it
				all. Energy in motion is not positive or negative, it just is
				and we should allow it to be that way.
			</p>
			<p>
				So what do we do? How do we start to shift out of our obsession
				with everything being fine into a more <i>buzz word</i>{" "}
				authentic way of being? 
			</p>
			<ol>
				<li>
					<span>Start with yourself: </span>Jumping from rarely
					talking about feelings to sharing it all with people close
					to us can feel like a big jump, and likely one which will
					end up being a hurdle to doing anything at all.  First
					things first, just start to acknowledge how you are feeling 
					<i>to yourself. </i>Set yourself a timer a couple of times a
					day if you want to make it into a formal practice and every
					time it goes off, notice how you feel. Notice where the
					emotion sits in your body and just voice (internally) how
					you are feeling. ‘I notice I feel fear’ ‘I notice I feel
					excitement’ ‘I notice I feel happiness’. Sounds basic but a
					lot of us aren’t particularly well connected with our
					emotional states day to day and this can be an incredible
					first step. 
				</li>
				<li>
					<span>Let feelings be there: </span>Another tendency a lot
					of us have is to avoid ‘uncomfortable’ feelings and to numb
					them with our ‘coping mechanisms’ (enter wine, food,
					exercise, scrolling, being busy etc.). This is another way
					we try to avoid noticing how we are actually doing. The
					problem with doing this is that we are covering up big
					gaping wounds with little plasters, it may work short term
					but in the long run, it’s not going to cut it. When we use
					step 1 to recognise how we are feeling, the next step is to
					see if we can just sit with whatever the feeling is for a
					short period of time (it can be 30 seconds to start with).
					Just sit with it, notice how it feels, try to avoid diving
					for your phone or anything else to take yourself away from
					the sensations. Just sit with them repeating ‘I notice I
					feel xyz’ whilst taking long slow breaths. 
				</li>
				<li>
					<span>Sharing: </span>When you feel ready to start sharing
					some of your, perhaps more hidden, feelings – choose wisely.
					One of the problems that comes up for people when we start
					to share is that we aren’t careful with who we share it
					with. The result of this is that you can end up with
					responses like ‘Oh don’t feel like that, it will be fine’
					which can result in us feeling seriously unheard and likely
					stop us from wanting to share again. Find people in your
					life who you feel have the emotional capacity to hold your
					feelings without wanting to change them. You don’t need to
					be able to explain why you feel a certain way. All your
					feelings are valid, so share them with people who will
					support you in that. If you don’t have anyone in your life
					who you feel comfortable doing this with, it can be worth
					hiring someone to work with to help support you in deepening
					emotional intimacy. 
				</li>
			</ol>
			<p>
				This work isn’t necessarily the easiest, but its rewards are
				worth their weight in gold. Stick with it and let me know how
				you get on! 
			</p>
			<p>
				If anyone is interested in diving into this work in more detail,
				I am available for both coaching and breathwork sessions, both
				of which are hugely powerful in unknotting some of the sticky
				stuff that keeps us from living the lives we so desperately
				want. 
			</p>
			<p>
				<a
					href="http://www.lucyputtergill.com"
					target="_blank"
					rel="noreferrer">
					www.lucyputtergill.com
				</a>
			</p>
		</>
	);
};
