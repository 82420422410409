import { Skill } from "./schemas";

const SkillData: Skill[] = [
	{
		id: "1",
		title: "Building Mastery",
		tag: "skill",
		file: "Building Mastery skill",
	},
	{
		id: "2",
		title: "Breathing and paired relaxation",
		tag: "skill",
		file: "Breathing and paired relaxation skill",
	},
	{
		id: "3",
		title: "Building Mastery",
		tag: "worksheet",
		file: "Building Mastery Worksheet",
	},
	{
		id: "4",
		title: "Challenging Myths",
		tag: "worksheet",
		file: "Challenging Myths worksheet",
	},
	{
		id: "5",
		title: "Challenging Skill",
		tag: "skill",
		file: "Challenging Skill",
	},
	{
		id: "6",
		title: "Challenging Worksheet",
		tag: "worksheet",
		file: "Challenging Worksheet",
	},
	{
		id: "7",
		title: "Check the facts",
		tag: "skill",
		file: "Check the facts skill",
	},
	{
		id: "8",
		title: "Checking the facts worksheet",
		tag: "worksheet",
		file: "Checking the facts worksheet",
	},
	{
		id: "9",
		title: "Mindfulness",
		tag: "skill",
		file: "Mindfulness",
	},
	{
		id: "10",
		title: "Opposite Action",
		tag: "skill",
		file: "Opposite Action",
	},
	{
		id: "11",
		title: "Problem Solving",
		tag: "skill",
		file: "Problem Solving",
	},
	{
		id: "12",
		title: "Building Positive Emotions",
		tag: "skill",
		file: "Building Positive Emotions",
	},
	{
		id: "13",
		title: "Building Skill Development",
		tag: "skill",
		file: "Building Skill Development",
	},
	{
		id: "14",
		title: "Cope Ahead Skill",
		tag: "skill",
		file: "Cope Ahead Skill",
	},
	{
		id: "15",
		title: "Using PLEASE Skills",
		tag: "skill",
		file: "Using PLEASE Skills",
	},
	{
		id: "16",
		title: "Using Temperature",
		tag: "skill",
		file: "Using Temperature",
	},
	{
		id: "17",
		title: "Challenging Your Thoughts",
		tag: "skill",
		file: "Challenging Your Thoughts",
	},
	{
		id: "18",
		title: "Effective Communication",
		tag: "skill",
		file: "Effective Communication",
	},
	{
		id: "19",
		title: "Fact Checking",
		tag: "skill",
		file: "Fact Checking",
	},
	{
		id: "20",
		title: "Intense Exercise",
		tag: "skill",
		file: "Intense Exercise",
	},
	{
		id: "21",
		title: "Learn To Cope Ahead",
		tag: "skill",
		file: "Learn To Cope Ahead",
	},
	{
		id: "22",
		title: "Managing Problems Effectively",
		tag: "skill",
		file: "Managing Problems Effectively",
	},
	{
		id: "23",
		title: "Relaxation Techniques",
		tag: "skill",
		file: "Relaxation Techniques",
	},
	{
		id: "24",
		title: "Self-Soothing Techniques",
		tag: "skill",
		file: "Self-Soothing Techniques",
	},
	{
		id: "25",
		title: "STOP Skill",
		tag: "skill",
		file: "Stop Skill",
	},
	{
		id: "26",
		title: "Thinking of Pros & Cons",
		tag: "skill",
		file: "Thinking of Pros & Cons",
	},
	{
		id: "27",
		title: "Distracting Techniques",
		tag: "skill",
		file: "Distracting Techniques",
	},
	{
		id: "28",
		title: "Gratitude Journaling",
		tag: "skill",
		file: "Gratitude Journaling",
	},
	{
		id: "29",
		title: "Identifying Unhealthy Relationships",
		tag: "skill",
		file: "Identifying Unhealthy Relationships",
	},
	{
		id: "30",
		title: "Improve the Moment",
		tag: "skill",
		file: "Improve the Moment",
	},
	{
		id: "31",
		title: "Loving Kindness Meditation",
		tag: "skill",
		file: "Loving Kindness Meditation",
	},
	{
		id: "32",
		title: "Radical Acceptance",
		tag: "skill",
		file: "Radical Acceptance",
	},
	{
		id: "33",
		title: "Filtering your Filters",
		tag: "skill",
		file: "Filtering your Filters",
	},
	{
		id: "34",
		title: "Identifying Your A-Team",
		tag: "skill",
		file: "Identifying Your A-Team",
	},
	{
		id: "35",
		title: "Validation",
		tag: "skill",
		file: "Validation",
	},
	{
		id: "36",
		title: "Goal & Activity Tracker",
		tag: "worksheet",
		file: "Goal & Activity Tracker",
	},
	{
		id: "37",
		title: "Creating Healthy Boundaries",
		tag: "worksheet",
		file: "Creating Healthy Boundaries",
	},
	{
		id: "38",
		title: "Long-Term Goal Setting",
		tag: "worksheet",
		file: "Long-Term Goal Setting To Build Positive Emotions",
	},
];

export default SkillData;
