import React, { useState } from "react";
import Modal from "react-bootstrap/modal";
import Button from "../Button";
import PreviewTile from "../PreviewTile";

type Props = {
	id: string;
	title: string;
	previewDisplay: string;
	noMargin?: boolean;
};

export default ({ id, title, previewDisplay, noMargin }: Props) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<>
			<PreviewTile
				title={title}
				previewDisplay={previewDisplay}
				type="video"
				color="blue"
				onClick={handleShow}
				noMargin={noMargin}
			/>
			<Modal
				show={show}
				onHide={handleClose}
				dialogClassName="video-modal">
				<Modal.Body>
					<div className="video-responsive">
						<iframe
							width="853"
							height="480"
							src={`https://www.youtube.com/embed/${id}`}
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							title="Embedded youtube"
						/>
					</div>
				</Modal.Body>
				<div className="row justify-content-center d-flex">
					<Button
						ariaLabel="close-video-window"
						text="Close window"
						color="white"
						classOverride="close-window"
						onClick={handleClose}
					/>
				</div>
			</Modal>
		</>
	);
};
