import React, { useEffect, useState } from "react";
import { ReactComponent as SkillsIcon } from "../../../imgs/icons/skills-toolkit.svg";
import { ReactComponent as Arrow } from "../../../imgs/icons/arrow.svg";
import Button from "../../Button";
import PreviewTile from "../../PreviewTile";
import useWindowSize from "../../../hooks/useWindowSize";

import SkillData from "../../../JSON/SkillData";
import { Skill } from "../../../JSON/schemas";
import { useHistory } from "react-router-dom";

function SkillsToolkitTile() {
	const size = useWindowSize();
	// Mobile up to 1200 as it works nicer
	const isMobile = size.width !== undefined && size.width < 1200;
	const [skills, setSkills] = useState<Skill[]>([]);
	const history = useHistory();
	useEffect(() => {
		const skillsTemp: Skill[] = [];
		for (let i = 0; i < 4; i++) {
			skillsTemp.push(SkillData[i]);
		}
		setSkills(skillsTemp);
	}, []);

	const text: JSX.Element = (
		<span>
			See all Resources &nbsp;
			<Arrow stroke="white"></Arrow>
		</span>
	);
	return (
		<div className="toolkit-tile">
			<div className="content mx-auto">
				<div className="row">
					<div className="col-xl-6 col-md-12 col-sm-12 justify-content-center d-flex">
						<div className="box">
							<SkillsIcon className="skills-icon" />
							<h2>Skills Toolkit</h2>
							<p className="mx-auto">
								Practical skills and worksheets to help you
								increase skillful behaviours and transform your
								toolbox ensuring you thrive on your wellbeing
								journey.
							</p>
							{!isMobile && (
								<Button
									ariaLabel="all-skills"
									text={text}
									color="green"
									classOverride="skill-button"
									onClick={() =>
										history.push("/skills-toolkit")
									}
								/>
							)}
						</div>
					</div>
					{skills.length === 4 && (
						<div className="col-xl-6 col-lg-7 col-sm-10 preview-tiles mx-auto">
							<div className="row">
								<PreviewTile
									title={skills[0].title}
									type="skill"
									color="green"
									tag={skills[0].tag}
									onClick={() =>
										window.open(
											// eslint-disable-next-line @typescript-eslint/no-var-requires
											require(`../../../Documents/Skills/${skills[0].file}.pdf`)
												.default
										)
									}
								/>
								<PreviewTile
									title={skills[1].title}
									type="skill"
									color="green"
									tag={skills[1].tag}
									onClick={() =>
										window.open(
											// eslint-disable-next-line @typescript-eslint/no-var-requires
											require(`../../../Documents/Skills/${skills[1].file}.pdf`)
												.default
										)
									}
								/>
							</div>
							<div className="row">
								<PreviewTile
									title={skills[2].title}
									type="skill"
									color="green"
									tag={skills[2].tag}
									onClick={() =>
										window.open(
											// eslint-disable-next-line @typescript-eslint/no-var-requires
											require(`../../../Documents/Skills/${skills[2].file}.pdf`)
												.default
										)
									}
								/>

								<PreviewTile
									title={skills[3].title}
									type="skill"
									color="green"
									tag={skills[3].tag}
									onClick={() =>
										window.open(
											// eslint-disable-next-line @typescript-eslint/no-var-requires
											require(`../../../Documents/Skills/${skills[3].file}.pdf`)
												.default
										)
									}
								/>
							</div>
						</div>
					)}
					<div className="col-sm-12 see-all-btn">
						{isMobile && (
							<Button
								ariaLabel="all-skills"
								text={text}
								color="green"
								classOverride="skill-button"
								onClick={() => history.push("/skills-toolkit")}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SkillsToolkitTile;
