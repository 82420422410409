import React, { useEffect, useState } from "react";
import Button from "../Button";
import Footer from "../Footer";
import NavBar from "../NavBar";
import { Link } from "react-router-dom";
import { ReactComponent as OnTrack } from "../../imgs/icons/on-track.svg";
import { ReactComponent as Arrow } from "../../imgs/icons/arrow.svg";
import { useHistory } from "react-router-dom";
import JournalData from "../../JSON/JournalData";
import SkillData from "../../JSON/SkillData";
import VideoData from "../../JSON/VideoData";
import PreviewTile from "../PreviewTile";
import useWindowSize from "../../hooks/useWindowSize";
import { Journal, Skill, Video } from "../../JSON/schemas";
import MMDrop from "../MMDrop";
import VideoTile from "../VideoTile";
import Subscribe from "../Subscribe/Subscribe";

type Props = {
	color: "purple" | "green" | "blue";
	type: "journal" | "skill" | "video";
};

type Resource = {
	journal: Journal[];
	skill: Skill[];
	video: Video[];
};

export default ({ color, type }: Props) => {
	const history = useHistory();
	const [page, setPage] = useState(1);
	const [Resource, setResource] = useState<Resource>({
		journal: [],
		skill: [],
		video: [],
	});
	const [tag, setTag] = useState<string | undefined>(undefined);
	const size = useWindowSize();
	const isMobile = size.width !== undefined && size.width < 992;
	const prevText: any = (
		<span className="prev-page">
			<Arrow stroke="white"></Arrow>
			&nbsp; Newer posts
		</span>
	);
	const nextText: any = (
		<span>
			Older posts &nbsp;
			<Arrow stroke="white"></Arrow>
		</span>
	);
	const pagination: number[] = [];
	for (
		let i = 0;
		i <
		Math.ceil(
			type === "journal"
				? JournalData.length / 8
				: type === "skill"
				? SkillData.length / 8
				: VideoData.length / 8
		);
		i++
	) {
		pagination.push(i + 1);
	}

	useEffect(() => {
		if (tag === undefined || tag === "emptyValue") {
			if (type === "journal")
				setResource({
					journal: JournalData,
					skill: [],
					video: [],
				});
			else if (type === "skill")
				setResource({ skill: SkillData, journal: [], video: [] });
			else if (type === "video")
				setResource({ video: VideoData, journal: [], skill: [] });
		} else {
			if (type === "skill")
				setResource({
					skill: SkillData.filter(skill => {
						if (skill.tag === tag) return true;
						else return false;
					}),
					journal: [],
					video: [],
				});
			else if (type === "video")
				setResource({
					video: VideoData.filter(video => {
						let found = false;
						video.tags?.forEach(videoTag => {
							if (videoTag === tag) found = true;
						});
						return found;
					}),
					journal: [],
					skill: [],
				});
		}
	}, [tag]);

	return (
		<div className="container-flex" style={{ width: "100vw" }}>
			<NavBar />
			<div className="journal-page">
				<div
					className={`journal-top ${
						type === "journal"
							? "journal-top--purple"
							: type === "skill"
							? "journal-top--green"
							: "journal-top--blue"
					}`}>
					<div className="content mx-auto">
						<div className="row justify-content-center">
							<div className="col-lg-5 col-md-12 col-sm-1 order-sm-1 justify-content-center d-flex my-auto">
								<div className="box">
									{type === "journal" && (
										<>
											<h1>Journal</h1>
											<p>
												Our journal articles will
												inspire you along your wellbeing
												journey. We know how isolating
												experiences can feel, that's why
												every month we will be sharing
												real-life inspirational stories
												from our Mindscape community as
												well as the latest advice and
												tips on managing your mental
												health.
											</p>
											<Link
												to="/get-involved"
												aria-label="Get Involved">
												<Button
													text={
														"Want to get involved?"
													}
													color={color}
													onClick={() =>
														window.scrollTo({
															behavior: "smooth",
															top: 0,
														})
													}
													ariaLabel={
														"want-to-get-involved-button"
													}
												/>
											</Link>
										</>
									)}
									{type === "skill" && (
										<>
											<h1>Skills Toolkit</h1>
											<p>
												Ready to empower yourself with
												the skills to thrive? Our
												toolkit contains all the skills
												and worksheets so that you can
												access and practise in your own
												time. Building your own toolbox
												that work for you is an
												essential part of being able to
												regulate your emotional
												responses and build resilience.
											</p>
											<MMDrop
												placeHolder={"Select filter"}
												rating={tag}
												setRating={setTag}
												values={[
													"emptyValue",
													"skill",
													"worksheet",
												]}
											/>
										</>
									)}
									{type === "video" && (
										<>
											<h1>Mindful Conversations</h1>
											<p>
												Check out our bitesize Mindful
												Conversations with experts and
												inspirational individuals and
												empower yourself with knowledge
												and skills to help you on your
												wellbeing journey. Every month a
												new series will address a range
												of difficult current topics so
												stay tuned.
											</p>
											<p>
												You can also check out the
												extended version of our Mindful
												Conversations on &nbsp;
												<a
													href="https://podcasts.apple.com/gb/podcast/mindful-conversations/id1582858291"
													target="_blank"
													rel="noreferrer">
													Apple Podcast
												</a>
												&nbsp; and &nbsp;
												<a
													href="https://open.spotify.com/show/2RhgAZ6VbHhOMDpyFOpZXg?si=3XxRJ-YnRIusDfU1TPgyVQ&dl_branch=1&nd=1"
													target="_blank"
													rel="noreferrer">
													Spotify
												</a>
												. Be sure to follow us so you
												don’t miss an episode!
											</p>
											<MMDrop
												placeHolder={"Select filter"}
												rating={tag}
												setRating={setTag}
												values={[
													"emptyValue",
													"Real-Life Experiences",
													"Conversations with Wellbeing Leaders",
													"Live Skill Practices",
												]}
											/>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				{(type === "journal" && Resource.journal.length > 0) ||
				(type === "skill" && Resource.skill.length > 0) ||
				(type === "video" && Resource.video.length > 0) ? (
					<div
						className={`journals-container${
							type === "journal"
								? ""
								: " journals-container--margin-bottom"
						} mx-auto`}>
						<div className="row justify-content-center">
							{type === "journal" &&
								Resource.journal.map((journal, index) => {
									if (
										(!isMobile &&
											((page === 1 && index < 8) ||
												(index >= (page - 1) * 8 &&
													index < page * 8))) ||
										(isMobile && index < page * 8)
									)
										return (
											<div
												className="col-xl-3 col-lg-4 col-md-5 col-sm-12 justify-content-center d-flex"
												key={`journals-${journal.id}`}>
												<PreviewTile
													title={journal.title}
													previewDisplay={
														journal.image
													}
													type="journal"
													color={color}
													onClick={() =>
														history.push(
															`/journal/${journal.id}`
														)
													}
													noMargin={true}
												/>
											</div>
										);
									else return <></>;
								})}
							{type === "skill" &&
								Resource.skill.map((skill, index) => {
									if (
										(!isMobile &&
											((page === 1 && index < 8) ||
												(index >= (page - 1) * 8 &&
													index < page * 8))) ||
										(isMobile && index < page * 8)
									)
										return (
											<div
												className="col-xl-3 col-lg-4 col-md-5 col-sm-12 justify-content-center d-flex"
												key={`resources-skill-${skill.id}`}>
												<PreviewTile
													title={skill.title}
													type={"skill"}
													color={color}
													onClick={() =>
														window.open(
															// eslint-disable-next-line @typescript-eslint/no-var-requires
															require(`../../Documents/Skills/${skill.file}.pdf`)
																.default
														)
													}
													noMargin={true}
													tag={skill.tag}
												/>
											</div>
										);
									else return <></>;
								})}
							{type === "video" &&
								Resource.video.map((video, index) => {
									if (
										(!isMobile &&
											((page === 1 && index < 8) ||
												(index >= (page - 1) * 8 &&
													index < page * 8))) ||
										(isMobile && index < page * 8)
									)
										return (
											<div
												className="col-xl-3 col-lg-4 col-md-5 col-sm-12 justify-content-center d-flex"
												key={`resources-video-${video.id}`}>
												<VideoTile
													title={video.title}
													previewDisplay={video.link}
													id={video.link}
													noMargin={true}
												/>
											</div>
										);
									else return <></>;
								})}
						</div>
						{((type === "journal" && Resource.journal.length > 8) ||
							(type === "skill" && Resource.skill.length > 8) ||
							(type === "video" &&
								Resource.video.length > 8)) && (
							<div className="journals-pagination">
								{isMobile ? (
									page !== pagination.length && (
										<Button
											text={nextText}
											ariaLabel="load-more"
											color={color}
											onClick={() => setPage(page + 1)}
											classOverride={"load-more-btn"}
										/>
									)
								) : (
									<>
										<Button
											text={prevText}
											ariaLabel="prev-page"
											color={color}
											onClick={() => setPage(page - 1)}
											disabled={page === 1}
											classOverride={
												page === 1
													? "floatLeft disabled"
													: "floatLeft"
											}
										/>
										{pagination.map(val => {
											return (
												<>
													<span
														className={`pages ${
															page === val
																? "active"
																: "inactive"
														}`}
														onClick={
															page === val
																? undefined
																: () =>
																		setPage(
																			val
																		)
														}>
														{val}
													</span>
													{val !==
														pagination.length && (
														<span>, </span>
													)}
												</>
											);
										})}
										<Button
											text={nextText}
											ariaLabel="next-page"
											color={color}
											onClick={() => setPage(page + 1)}
											disabled={
												(type === "journal" &&
													page ===
														Math.ceil(
															Resource.journal
																.length / 8
														)) ||
												(type === "skill" &&
													page ===
														Math.ceil(
															Resource.skill
																.length / 8
														)) ||
												(type === "video" &&
													page ===
														Math.ceil(
															Resource.video
																.length / 8
														))
											}
											classOverride={
												(type === "journal" &&
													page ===
														Math.ceil(
															Resource.journal
																.length / 8
														)) ||
												(type === "skill" &&
													page ===
														Math.ceil(
															Resource.skill
																.length / 8
														)) ||
												(type === "video" &&
													page ===
														Math.ceil(
															Resource.video
																.length / 8
														))
													? "floatRight disabled"
													: "floatRight"
											}
										/>
									</>
								)}
							</div>
						)}
					</div>
				) : (
					"404 content not found"
				)}
				{type === "journal" && (
					<div className="tiles-container row mx-auto">
						<Subscribe />

						<div className="get-involved-journal mx-auto">
							<div className="inner">
								<h3>Get involved</h3>
								<p>
									If you'd like to get involved and submit a
									journal post we'd love to hear from you!
								</p>

								<div className="button-container">
									<Link
										to="/get-involved"
										aria-label="Get Involved">
										<Button
											ariaLabel="get-started"
											text="Get in touch"
											color="black"
											onClick={() =>
												window.scrollTo({
													behavior: "smooth",
													top: 0,
												})
											}
										/>
									</Link>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<Footer />
		</div>
	);
};
