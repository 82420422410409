import { Video } from "./schemas";

const VideoData: Video[] = [
	{
		id: "4",
		title: "Jonny Benjamin, MBE & award-winning mental health campaigner.",
		link: "OqI_Ui7jxBc",
		tags: ["Real-Life Experiences"],
	},
	{
		id: "3",
		title:
			"Jacqueline Gomes-Neves, first ever female Youth Mayor for Lambeth.",
		link: "xvk4sQyB-3g",
		tags: ["Real-Life Experiences"],
	},
	{
		id: "2",
		title: "Ed Todino, an inspirational young man living with disability.",
		link: "8m8u6HBpnaU",
		tags: ["Real-Life Experiences"],
	},
	{
		id: "1",
		title:
			"Jack Dacombe, psychotherapist providing therapy for the 21st century.",
		link: "zhKG-rhlsx4",
		tags: ["Conversations with Wellbeing Leaders"],
	},
	{
		id: "5",
		title: "Lucy Puttergill, Coach and Breathwork Specialist",
		link: "EsjprdwyccU",
		tags: ["Live Skill Practices"],
	},
];

export default VideoData;
