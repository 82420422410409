import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./scss/_base.scss";
import App from "./App";
import ScrollToTop from "./layout/ScrollToTop";

ReactDOM.render(
	<BrowserRouter>
		<React.StrictMode>
			<ScrollToTop />
			<App />
		</React.StrictMode>
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
