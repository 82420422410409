import axios from "axios";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { ReactComponent as OnTrack } from "../../imgs/icons/on-track.svg";
import Button from "../Button";

function Subscribe() {
	const [validated, setValidated] = useState(false);
	const [email, setEmail] = useState("");
	const [status, setStatus] = useState("");
	const [message, setMessage] = useState("");
	const API_KEY = "6a09468d59021f3c1e1c6ae1316bfff4-us1";

	const handleSubmit = (event: any) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		setValidated(true);

		const data = {
			members: [
				{
					email_address: email,
					status: "subscribed",
				},
			],
		};

		const postData = JSON.stringify(data);
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.post["Content-Type"] =
			"application/json;charset=utf-8";

		const axiosConfig = {
			headers: {
				authorization:
					"Basic " +
					Buffer.from("randomstring:" + API_KEY).toString("base64"),
			},
		};
		axios
			.post("/3.0/lists/bbee61bb9e", postData, axiosConfig)
			.then(response => {
				console.log("RESPONSE RECEIVED: ", response);
				setStatus("success");
				setMessage("Thanks for subscribing!");
			})
			.catch(error => {
				console.log("AXIOS ERROR: ", error);
				setStatus("error");
				setMessage("Error while trying to subscribe");
			});
	};

	return (
		<div className="sign-up mx-auto">
			<div className="on-track justify-content-center d-flex">
				<OnTrack />
			</div>
			<h3>Keep on track of your journey</h3>
			<p>
				Subscribe to our newsletter for updates on new videos,
				worksheets, advice and more
			</p>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Form.Group controlId="validationCustom01">
					<Form.Control
						type="email"
						placeholder="Enter email here"
						required
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<FormControl.Feedback type="invalid">
						Please enter a valid email
					</FormControl.Feedback>
				</Form.Group>

				<div className="button-container">
					<Button
						ariaLabel="subscribe"
						text="Subscribe"
						color="black"
						onClick={() => handleSubmit}
						type="submit"
					/>
				</div>
				<p className={`subscribe subscribe--${status}`}>{message}</p>
			</Form>
		</div>
	);
}

export default Subscribe;
