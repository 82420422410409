import React from "react";

type Props = {
	text: JSX.Element | string;
	onClick?: () => void;
	color: string;
	ariaLabel: string;
	classOverride?: string;
	disabled?: boolean;
	type?: "button" | "submit" | "reset" | undefined;
};

export default ({
	text,
	onClick,
	color,
	ariaLabel,
	classOverride,
	disabled,
	type,
}: Props) => {
	return (
		<button
			aria-label={ariaLabel}
			className={`std-button std-button--${color}${
				classOverride !== undefined ? ` ${classOverride}` : ""
			}`}
			onClick={onClick}
			disabled={disabled}
			type={type}>
			{text}
		</button>
	);
};
