import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import NavBar from "../NavBar";
import _, { toInteger } from "lodash";
import { EmotionsList, EmotionsStrings } from "../../JSON/schemas";
import JournalData from "../../JSON/JournalData";
import SkillData from "../../JSON/SkillData";
import EmotionData from "../../JSON/EmotionData";
import VideoData from "../../JSON/VideoData";
import PreviewTile from "../PreviewTile";
import VideoTile from "../VideoTile";

export default () => {
	const { emotion, intensity } = useParams<{
		emotion: EmotionsStrings;
		intensity: string;
	}>();
	const intensityInt = toInteger(intensity);
	const history = useHistory();
	const [resources, setResources] = useState<EmotionsList[]>([]);
	useEffect(() => {
		if (
			(emotion !== "happy" &&
				emotion !== "sad" &&
				emotion !== "guilt" &&
				emotion !== "love" &&
				emotion !== "anger" &&
				emotion !== "jealous" &&
				emotion !== "shame" &&
				emotion !== "fear") ||
			intensityInt < 1 ||
			intensityInt > 10
		) {
			history.push("/mind-mapper");
		}
		const resourcesTemp: EmotionsList[] = [];
		const level =
			intensityInt < 5
				? "level1"
				: intensityInt >= 5 && intensityInt <= 7
				? "level2"
				: "level3";
		EmotionData[emotion][level].forEach(emotionResource => {
			switch (emotionResource.type) {
				case "journal":
					JournalData.forEach(journal => {
						if (journal.id === emotionResource.id) {
							resourcesTemp.push({
								journal: journal,
							});
							return;
						}
					});
					break;
				case "video":
					VideoData.forEach(video => {
						if (video.id === emotionResource.id) {
							resourcesTemp.push({
								video: video,
							});
							return;
						}
					});
					break;
				case "skill":
					SkillData.forEach(skill => {
						if (skill.id === emotionResource.id) {
							resourcesTemp.push({
								skill: skill,
							});
							return;
						}
					});
					break;
			}
		});
		setResources(resourcesTemp);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<NavBar></NavBar>
			<div className="mind-mapper mind-mapper-emotions">
				<div className="content-resources mx-auto d-flex">
					<div className="box mx-auto">
						<h1>Mind Mappers recommendations.</h1>
						<p>
							You’ve chosen <span>{_.startCase(emotion)}</span> at
							an intensity of{" "}
							<span>{toInteger(intensityInt)}</span>.
							<br />
							Here are our recommended tools that may help you.
						</p>
					</div>
				</div>
				<div className="resources-container mx-auto">
					<div className="row justify-content-center">
						{resources.length > 0 &&
							resources.map((resource, index) => {
								if (resource.journal !== undefined)
									return (
										<div
											className="col-xl-3 col-lg-4 col-md-5 col-sm-12 justify-content-center d-flex"
											key={`resources-journal-${resource.journal.id}`}>
											<PreviewTile
												title={resource.journal.title}
												previewDisplay={
													resource.journal.image
												}
												type={"journal"}
												color="purple"
												onClick={() =>
													history.push(
														`/journal/${
															resource.journal!.id
														}`
													)
												}
												noMargin={true}
											/>
										</div>
									);
								else if (resource.video !== undefined)
									return (
										<div
											className="col-xl-3 col-lg-4 col-md-5 col-sm-12 justify-content-center d-flex"
											key={`resources-video-${resource.video.id}`}>
											<VideoTile
												title={resource.video.title}
												previewDisplay={
													resource.video.link
												}
												id={resource.video.link}
												noMargin={true}
											/>
										</div>
									);
								else if (resource.skill !== undefined)
									return (
										<div
											className="col-xl-3 col-lg-4 col-md-5 col-sm-12 justify-content-center d-flex"
											key={`resources-skill-${resource.skill.id}`}>
											<PreviewTile
												title={resource.skill.title}
												type={"skill"}
												color="green"
												onClick={() =>
													window.open(
														// eslint-disable-next-line @typescript-eslint/no-var-requires
														require(`../../Documents/Skills/${
															resource.skill!.file
														}.pdf`).default
													)
												}
												noMargin={true}
												tag={resource.skill.tag}
											/>
										</div>
									);
							})}
					</div>
				</div>
			</div>
		</>
	);
};
