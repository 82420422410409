import { startCase } from "lodash";
import skill from "../../imgs/background/skill-bg.svg";

type Props = {
	title: string;
	previewDisplay?: any;
	onClick: () => void;
	type: string;
	color: string;
	noMargin?: boolean;
	tag?: string;
};

export default ({
	title,
	previewDisplay,
	onClick,
	type,
	color,
	noMargin = false,
	tag,
}: Props) => {
	return (
		<div
			className={`preview${
				noMargin ? " preview-no-margin" : ""
			} preview-${color}`}
			onClick={onClick}>
			<img
				src={
					type !== "video"
						? type !== "skill"
							? // eslint-disable-next-line @typescript-eslint/no-var-requires
							  require(`../../imgs/journal/${previewDisplay}`)
									.default
							: skill
						: `https://img.youtube.com/vi/${previewDisplay}/hqdefault.jpg`
				}
				alt=""
				className={color}
			/>
			{type === "skill" && (
				<>
					<div className="skill-type">{startCase(tag)}</div>
					<div className="skill-name">{title}</div>
					<div className={`skill-icon skill-icon-${tag}`} />
				</>
			)}
			{type === "video" && (
				<>
					<div className="video-icon" />
				</>
			)}
			<h3>{title}</h3>
		</div>
	);
};
